import "./App.css";

function App() {
  return (
    <div className='App'>
      <h1>React Test App</h1>
      <div style={{ width: "70%", margin: "auto" }}>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sint,
          aspernatur beatae odit voluptates distinctio earum! Expedita quos esse
          possimus facere itaque ullam ut dolor. Veniam magnam ipsa distinctio
          veritatis voluptatem!
        </p>
      </div>
    </div>
  );
}

export default App;
